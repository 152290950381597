.homeQuestion {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

#warning {
  text-align: center;
  color: red;
}

#warningExisting {
  text-align: center;
  color: red;
}

.center {
  margin: auto;
}

.centerSendButton {
  display: flex;
  align-items: center;
}

.container {
  display: flex;
}

button.filtersbutton {
  --bs-btn-bg: gray;
  --bs-btn-border-color: gray;
  width: 100%;
}

.applyfilters {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginBox {
  width: 35vw;
  height: 200px;
  position: absolute;
  top:0;
  bottom: 50px;
  left: 0;
  right: 0;  
  margin: auto;
}
  
  
.logininput {
    width: 80%;
}
  
.input-group {
  position: relative;
  display: flex;
  align-items: center;
  width: 50%;
  margin: auto;
}
  
.btn {
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: #212529;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.375rem;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 30%;
    margin: auto;
    margin-bottom: 16px;
}
  
  
.bg-body-tertiary {
   --bs-bg-opacity: 1;
    background-color: #f2f2f2;
}
  
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: ;
    --bs-card-subtitle-color: ;
    --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: var(--bs-border-radius);
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
}

.UpcomingMealsTabs {
  margin: 1vw;
}


.homeds {
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 5vh;
  width: 90vw;
  text-align: center;
  height: 80px;    
  --bs-btn-bg: #aeaeae24;
}

.Home {
  display: flex;
  flex-direction: row;
}

.HomeCalendar {
  display: flex;
  flex-direction: row;
}
 


.AllUpcomingMeals {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.AllUpcomingMealshome {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.cardButtons {
  padding-left: 20px;
  height: 150px;
  display: grid;
}

  
@media (max-width: 1200px) {
    .loginBox {
      width: 50vw;
      height: fit-content;
      position: absolute;
      top:0;
      bottom: 50px;
      left: 0;
      right: 0;
      margin: auto;
    }
  
    .card {
      --bs-card-spacer-y: 1rem;
      --bs-card-spacer-x: 1rem;
      --bs-card-title-spacer-y: 0.5rem;
      --bs-card-title-color: ;
      --bs-card-subtitle-color: ;
      --bs-card-border-width: var(--bs-border-width);
      --bs-card-border-color: var(--bs-border-color-translucent);
      --bs-card-border-radius: var(--bs-border-radius);
      --bs-card-box-shadow: ;
      --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
      --bs-card-cap-padding-y: 0.5rem;
      --bs-card-cap-padding-x: 1rem;
      --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
      --bs-card-cap-color: ;
      --bs-card-height: ;
      --bs-card-color: ;
      --bs-card-bg: var(--bs-body-bg);
      --bs-card-img-overlay-padding: 1rem;
      --bs-card-group-margin: 0.75rem;
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      height: var(--bs-card-height);
      word-wrap: break-word;
      background-color: var(--bs-card-bg);
      background-clip: border-box;
      border: var(--bs-card-border-width) solid var(--bs-card-border-color);
      border-radius: var(--bs-card-border-radius);
    }

    .fixheight {
      height: 350px;
    }
}
  
@media (max-width: 750px) {
    .loginBox {
      width: 70vw;
      height: 200px;
      position: absolute;
      top:0;
      bottom: 50px;
      left: 0;
      right: 0;
      margin: auto;
    }
  
    .card {
      --bs-card-spacer-y: 1rem;
      --bs-card-spacer-x: 1rem;
      --bs-card-title-spacer-y: 0.5rem;
      --bs-card-title-color: ;
      --bs-card-subtitle-color: ;
      --bs-card-border-width: var(--bs-border-width);
      --bs-card-border-color: var(--bs-border-color-translucent);
      --bs-card-border-radius: var(--bs-border-radius);
      --bs-card-box-shadow: ;
      --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
      --bs-card-cap-padding-y: 0.5rem;
      --bs-card-cap-padding-x: 1rem;
      --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
      --bs-card-cap-color: ;
      --bs-card-height: ;
      --bs-card-color: ;
      --bs-card-bg: var(--bs-body-bg);
      --bs-card-img-overlay-padding: 1rem;
      --bs-card-group-margin: 0.75rem;
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      height: var(--bs-card-height);
      word-wrap: break-word;
      background-color: var(--bs-card-bg);
      background-clip: border-box;
      border: var(--bs-card-border-width) solid var(--bs-card-border-color);
      border-radius: var(--bs-card-border-radius);
    }
}
  
@media (max-width: 550px) {
    .loginBox {
      width: 95vw;
      height: 200px;
      position: absolute;
      top:0;
      bottom: 50px;
      left: 0;
      right: 0;
      margin: auto;
    }
  
    .card {
      --bs-card-spacer-y: 1rem;
      --bs-card-spacer-x: 1rem;
      --bs-card-title-spacer-y: 0.5rem;
      --bs-card-title-color: ;
      --bs-card-subtitle-color: ;
      --bs-card-border-width: var(--bs-border-width);
      --bs-card-border-color: var(--bs-border-color-translucent);
      --bs-card-border-radius: var(--bs-border-radius);
      --bs-card-box-shadow: ;
      --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
      --bs-card-cap-padding-y: 0.5rem;
      --bs-card-cap-padding-x: 1rem;
      --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
      --bs-card-cap-color: ;
      --bs-card-height: ;
      --bs-card-color: ;
      --bs-card-bg: var(--bs-body-bg);
      --bs-card-img-overlay-padding: 1rem;
      --bs-card-group-margin: 0.75rem;
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      height: var(--bs-card-height);
      word-wrap: break-word;
      background-color: var(--bs-card-bg);
      background-clip: border-box;
      border: var(--bs-card-border-width) solid var(--bs-card-border-color);
      border-radius: var(--bs-card-border-radius);
    }
}

.homeButtons {
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 5vh;
  width: 90vw;
  text-align: center;
  height: 80px;    
  --bs-btn-bg: #aeaeae24;
}

@media (max-width: 1000px) {
  .homeQuestion {
    text-align: center;
    margin-top: 15px;;
  }

  .homeButtons {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 5vh;
    width: 90vw;
    text-align: center;
    height: 80px;    
    --bs-btn-bg: #aeaeae24;
  }

  .HomeS {
    display: flex;
    flex-direction: column;
  }

  .HomeCalendar {
    display: flex;
    flex-direction: column;
  }
}

  
.fix {
  overflow-x: hidden;
}

.widenButton {
  border-top: 16px;
  width: 50%;
  margin: auto;
}

.flexSectionOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexSectionInner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 850px) {
  .flexSection {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}

@media (max-width: 950px) {
  .flexSectionOuter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 950px) {
  .flexSectionOuter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 550px) {
  .flexSectionInner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}



@media (max-width: 1200px) {
  .loginBox {
    width: 50vw;
    height: fit-content;
    position: absolute;
    top:0;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .mealSelectCard {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: ;
    --bs-card-subtitle-color: ;
    --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: var(--bs-border-radius);
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
  }

}

@media (max-width: 600px) {
  .loginBox {
    width: 90vw;
    height: fit-content;
    position: absolute;
    top:0;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .mealSelectCard {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: ;
    --bs-card-subtitle-color: ;
    --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: var(--bs-border-radius);
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    width: 90vw;
  }

}

@media (max-width: 1000px) {
  .input-group {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 80%;
    margin: auto;
}
}

img {
  height: 250px;
}

.width80 {
  width: 80%;
}

.addPeopleButton {
  --bs-btn-bg: grey;
  --bs-btn-border-color: grey;
  margin-left: 10%;
  width: 20%;
}

.newPersonButton {
  display: flex;
  justify-content: flex-end;
  width: 20%;
}

.input-group .form-control {
  margin-right: 10px;
  height: fit-content;
}

.centerButton {
  margin-left: 40%;
  width: 20%;
}

@media (max-width: 600px) {
  .centerButton {
    margin-left: 35%;
  }
}

.loginbuttona {
  text-decoration: none;
  color: white;
}

#chatArea {
  width: 30%;
  margin: auto;
}

.messageButton {
  float: left;
  width: 65%;
  margin-right: 5%;
}

.widthAuto {
  width: fit-content;
  margin: auto;
  margin-left: 0px;
}

.centerViewMeal {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.quotewidth {
  margin: auto;
  margin-top: 5%;
  width: 30%;
}

.popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust the value as needed */
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001; /* Should be higher than popup-overlay */
}

.row {
  margin: auto;
}

.form-group {
  width: 60%;
  margin: auto;
}

#directions {
  width: 80%;
  margin: auto;
}

.directions {
  width: 80%;
  margin: auto;
}